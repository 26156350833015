import { UploadPicture } from "views/app-views/components/upload-modal/upload-dropbox";

export enum ProductStatus {
  PUBLISHED = "published",
  DRAFT = "draft",
}

export type EditedImage = {
  aspectRatio?: number;
  imagePosition?: { x: number; y: number };
  imageSize?: { width: string; height: string };
  picture: UploadPicture | null;
  removedBackgroundImageUrl?: string;
  croppedImage?: string;
};
