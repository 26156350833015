import fetch from "auth/FetchInterceptor";

const CartService = {};

CartService.updateMetadata = function (id, data) {
  return fetch({
    url: `store/custom/carts/${id}`,
    method: "post",
    data,
  });
};

export default CartService;
