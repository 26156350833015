import { ThemeConfig } from "antd";
import {
  ACCENT,
  ACCENT_LIGHT,
  ACCENT_PALE,
  AVATAR_CONTAINER_SIZE,
  BG_GRAY,
  BLACK_BUTTON_ACTIVE_COLOR,
  BLACK_BUTTON_COLOR,
  BLACK_BUTTON_HOVER_COLOR,
  BORDER_RADIUS,
  BORDER_RADIUS_BUTTON,
  BORDER_RADIUS_MODAL,
  CONTROL_HEIGHT,
  CONTROL_HEIGHT_SM,
  FONT_FAMILY,
  FONT_FAMILY_CODE,
  GREY_100,
  GREY_200,
  GREY_300,
  GREY_400,
  GREY_50,
  GREY_600,
  GREY_700,
  LIGHTER_PRIMARY,
  LIGHT_GRAY,
  LIGHT_PRIMARY,
  LINE_HEIGHT,
  PADDING_HORIZONTAL_BUTTON,
  PRIMARY,
  TEXT_GRAY,
  TEXT_GRAY_LIGHT,
  TEXT_SUCCESS,
  WHITE,
} from "constants/ThemeConstant";

export const themeConfig: ThemeConfig = {
  token: {
    colorPrimary: PRIMARY,
    fontFamily: FONT_FAMILY,
    fontFamilyCode: FONT_FAMILY_CODE,
    colorText: "rgb(30,30,30)",
    colorTextBase: "rgb(30,30,30)",
    colorLinkHover: PRIMARY, // ex: backIcon for pageHeader component
    colorTextDescription: TEXT_GRAY_LIGHT,
    borderRadiusLG: 16,
    borderRadiusSM: 12,
  },
  components: {
    Alert: {
      colorInfoBg: LIGHTER_PRIMARY,
    },
    Avatar: {
      containerSize: AVATAR_CONTAINER_SIZE,
    },
    Modal: {
      borderRadiusSM: BORDER_RADIUS_MODAL,
      borderRadiusLG: BORDER_RADIUS_MODAL,
    },
    Button: {
      // override style for default 'middle' size
      controlHeight: CONTROL_HEIGHT,
      controlHeightSM: CONTROL_HEIGHT_SM,
      colorLink: ACCENT,
      colorLinkActive: ACCENT_PALE,
      colorLinkHover: ACCENT_LIGHT,
      // when hovering over button type="text"
      colorBgTextHover: LIGHTER_PRIMARY,
      //overrid primary button colors
      colorPrimary: BLACK_BUTTON_COLOR,
      colorPrimaryHover: BLACK_BUTTON_HOVER_COLOR,
      colorPrimaryActive: BLACK_BUTTON_ACTIVE_COLOR,
      paddingContentHorizontal: PADDING_HORIZONTAL_BUTTON,
      borderRadius: BORDER_RADIUS_BUTTON,
      textHoverBg: GREY_300,
      primaryShadow: "0 1px 0 rgba(1, 1, 1, 0.045)",
    },
    Input: {
      lineHeight: LINE_HEIGHT,
      borderRadius: BORDER_RADIUS,
      colorBorder: LIGHT_GRAY,
      hoverBorderColor: GREY_700,
      activeBorderColor: GREY_600,
      activeShadow: `0 0 0 1.5px ${GREY_400} !important`,
    },
    InputNumber: {
      // override style for default 'middle' size
      lineHeight: LINE_HEIGHT,
      borderRadius: BORDER_RADIUS,
      colorBorder: LIGHT_GRAY,
      hoverBorderColor: GREY_700,
      activeBorderColor: GREY_600,
      activeShadow: `0 0 0 1.5px ${GREY_400} !important`,
    },
    Layout: {
      colorBgLayout: BG_GRAY,
    },
    Menu: {
      subMenuItemBg: WHITE,
      itemSelectedBg: GREY_100,
      itemActiveBg: GREY_200,
      itemBorderRadius: 10,
    },
    Progress: {
      //override style for default color
      colorInfo: PRIMARY,
    },
    Radio: {
      buttonSolidCheckedBg: "white",
      buttonSolidCheckedColor: "black",
      buttonSolidCheckedHoverBg: LIGHTER_PRIMARY,
      buttonColor: "black",
    },
    Select: {
      // override style for default 'middle' size
      controlHeight: CONTROL_HEIGHT,
      borderRadius: BORDER_RADIUS,
      colorBorder: LIGHT_GRAY,
      optionSelectedColor: "white",
    },
    Dropdown: {},
    Steps: {
      colorPrimary: TEXT_SUCCESS,
    },
    Slider: {
      handleSize: 18,
      handleSizeHover: 19,
      controlSize: 18,
      handleLineWidthHover: 2,
    },
    Checkbox: {
      controlInteractiveSize: 20,
      borderRadiusSM: 20,
    },
    Switch: {
      colorPrimary: ACCENT,
    },
  },
};
