import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import useBodyClass from "hooks/useBodyClass";
import { useAccount } from "context/account-context";
import { themeConfig } from "configs/ThemeConfig";
import { ProtectedRoute } from "auth/protected-route";
import {
  AUTHENTICATED_ENTRY,
  protectedRoutes,
  publicRoutes,
  thirdPartyRedirectRoutes,
  unauthenticatedRoutes,
} from "configs/RoutesConfig";
import { PublicRoute } from "auth/public-route";
import LamaLoading from "components/shared-components/LoadingLama";
import Intercom from "@intercom/messenger-js-sdk";
import i18n from "configs/i18nextConfig";
import { useIsMobile } from "hooks/screen-size";
import { IS_PAAGE } from "configs/AppConfig";
import Loading from "components/shared-components/Loading";

export const AppRoutes = () => {
  const direction = "ltr";
  const currentAppLocale = AppLocale[i18n.language];
  useBodyClass(`dir-${direction}`);

  const { user } = useAccount();
  const APP_ID = "ne7h3qqb";

  const location = useLocation();
  const isMobile = useIsMobile();

  useEffect(() => {
    if (
      !location.pathname.startsWith("/studio/") &&
      (!isMobile || !location.pathname.startsWith("/my-page")) &&
      !location.pathname.startsWith("/tracking") &&
      !isMobile
    ) {
      // check the path

      if (!!user) {
        //Convert user.created_at Date unto unix number timestamp
        const convertedDate = new Date(user?.created_at).getTime();
        Intercom({
          app_id: APP_ID,
          email: user?.email,
          created_at: convertedDate,
          name: user?.first_name,
          user_hash: user?.intercom_hash,
          hide_default_launcher: false,
        });
      } else {
        Intercom({
          app_id: APP_ID,
          email: undefined,
          created_at: undefined,
          name: undefined,
          user_hash: undefined,
          hide_default_launcher: false,
        });
      }
    } else {
      Intercom({
        app_id: APP_ID,
        hide_default_launcher: true,
      });
    }
  }, [user, location.pathname, isMobile]);

  return (
    <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider
        theme={{ ...themeConfig }}
        locale={currentAppLocale.antd}
        direction={direction}
      >
        <Suspense
          fallback={
            <div className="cover-content loading text-center">
              <Loading />
            </div>
          }
        >
          <Routes>
            {publicRoutes.map((route, index) => {
              return (
                <Route
                  key={route.key + index}
                  path={route.path}
                  element={<route.element />}
                />
              );
            })}
            {thirdPartyRedirectRoutes.map((route, index) => {
              return (
                <Route
                  key={route.key + index}
                  path={route.path}
                  element={<route.element />}
                />
              );
            })}
            <Route path="/" element={<ProtectedRoute />}>
              <Route
                path="/"
                element={<Navigate replace to={AUTHENTICATED_ENTRY} />}
              />

              {protectedRoutes.map((route, index) => {
                return (
                  <Route
                    key={route.key + index}
                    path={route.path}
                    element={<route.element direction={""} />}
                  />
                );
              })}
              <Route
                path="*"
                element={
                  <Navigate to={IS_PAAGE ? "/my-page" : "/home"} replace />
                }
              />
            </Route>
            <Route path="/" element={<PublicRoute />}>
              {unauthenticatedRoutes.map((route, index) => {
                return (
                  <Route
                    key={route.key + index}
                    path={route.path}
                    element={<route.element />}
                  />
                );
              })}
            </Route>
          </Routes>
        </Suspense>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { locale, direction } = theme;
  const { token } = auth;
  return { locale, token, direction };
};

export default connect(mapStateToProps)(AppRoutes);
