import { createStore, applyMiddleware, compose } from "redux";
import reducers from "../reducers";

function configureStore(preloadedState?) {
  /*const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;*/
  const composeEnhancers =
    (window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"] as typeof compose) ||
    compose;

  const store = createStore(reducers, preloadedState, composeEnhancers());

  //Workaround to delete typescript issue

  if ((module as any).hot) {
    (module as any).hot.accept("../reducers/index", () => {
      const nextRootReducer = require("../reducers/index");
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

const store: any = configureStore();

export default store;
