import enUS from "antd/locale/en_US";
import frFR from "antd/locale/fr_FR";
import esES from "antd/locale/es_ES";
import ptBR from "antd/locale/pt_BR";
import itIT from "antd/locale/it_IT";

const AppLocale = {
  en: enUS,
  fr: frFR,
  es: esES,
  pt: ptBR,
  it: itIT,
};

export default AppLocale;
