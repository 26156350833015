import React, { useState, useEffect } from "react";
import { useAuth } from "context/auth-context"; // Adjust the import path as necessary
import { Navigate, Outlet } from "react-router-dom";
import { AUTHENTICATED_ENTRY } from "configs/RoutesConfig";

export const PublicRoute: React.FC = () => {
  const { token, authContextInitialized } = useAuth();
  const [isReadyToRedirect, setIsReadyToRedirect] = useState(false);

  useEffect(() => {
    // If authContext is not initialized, wait for 1 second before making a redirect decision
    if (!authContextInitialized) {
      const timeoutId = setTimeout(() => {
        setIsReadyToRedirect(true);
      }, 20);
      // Cleanup function to clear the timeout if the component unmounts earlier
      return () => clearTimeout(timeoutId);
    } else {
      return undefined;
    }
  }, [authContextInitialized]);

  // Decision making for rendering based on token presence, authContext initialization, or timeout
  if (authContextInitialized || isReadyToRedirect) {
    if (token) {
      // User is authenticated, redirect to the authenticated entry route
      return <Navigate to={AUTHENTICATED_ENTRY} replace />;
    } else {
      // No token found, render child routes
      return <Outlet />;
    }
  }

  // While waiting for authContext initialization or the timeout, render null or a loader
  // This ensures there is always a return value for every code path
  return null; // or a loading spinner/component
};
