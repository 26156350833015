import fetch from "auth/FetchInterceptor";
import {
  FRAMES_COLLECTION_ID,
  PAPERS_COLLECTION_ID,
  MATS_COLLECTION_ID,
} from "configs/AppConfig";

const ProductService = {};

//LAMA Products
ProductService.GetFrames = function () {
  return fetch({
    url: `/store/products?collection_id%5B%5D=${FRAMES_COLLECTION_ID}`,
    method: "get",
  });
};

ProductService.GetPapers = async () => {
  return fetch({
    url: `/store/products?collection_id%5B%5D=${PAPERS_COLLECTION_ID}`,
    method: "get",
  });
};

ProductService.GetMats = async () => {
  return fetch({
    url: `/store/products?collection_id%5B%5D=${MATS_COLLECTION_ID}`,
    method: "get",
  });
};

ProductService.GetLamaVariantById = async (id) => {
  return fetch({
    url: `/store/variants/${id}`,
    method: "get",
  });
};

ProductService.GetLamaVariantsByIdList = async (list) => {
  const variantQuery = list.join(",");
  return fetch({
    url: `/store/variants?ids%5B%5D=${variantQuery}`,
    method: "get",
  });
};

ProductService.CreateProduct = async (data) => {
  return fetch({
    url: `/admin/products`,
    method: "post",
    data,
  });
};

ProductService.UploadPackagingLogoToS3 = async (data, onProgressUpload) => {
  return fetch({
    url: `/admin/customization/packaging-logo`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data,
    onUploadProgress: onProgressUpload,
  });
};

ProductService.UploadInvoicingLogoToS3 = async (data, onProgressUpload) => {
  return fetch({
    url: `/admin/customization/invoicing-logo`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data,
    onUploadProgress: onProgressUpload,
  });
};

ProductService.DeleteInvoicingLogoToS3 = async (data) => {
  return fetch({
    url: `/admin/customization/invoicing-logo`,
    method: "delete",
    data: { data },
  });
};

ProductService.GetProductsByHandle = async (handle) => {
  return fetch({
    url: `/admin/products?handle=${handle}`,
    method: "get",
  });
};

ProductService.CheckExistingHandle = async (handle) => {
  return fetch({
    url: `/admin/products/handle/${handle}`,
    method: "get",
  });
};

ProductService.GetProductById = async (id) => {
  return fetch({
    url: `/admin/products/${id}`,
    method: "get",
  });
};

ProductService.GetVariantByIds = async (productId, variantId) => {
  return fetch({
    url: `/admin/products/${productId}/variants/${variantId}`,
    method: "get",
  });
};

ProductService.GetVariant = async (variantId) => {
  return fetch({
    url: `/store/variants/${variantId}`,
    method: "get",
  });
};

ProductService.CreateVariant = async (productId, data) => {
  return fetch({
    url: `/admin/products/${productId}/variants`,
    method: "post",
    data,
  });
};

ProductService.UpdateVariant = async (productId, variantId, data) => {
  return fetch({
    url: `/admin/products/${productId}/variants/${variantId}`,
    method: "post",
    data,
  });
};

ProductService.DeleteVariant = async (productId, variantId) => {
  return fetch({
    url: `/admin/products/${productId}/variants/${variantId}`,
    method: "delete",
  });
};

ProductService.GetMyProducts = async () => {
  return fetch({
    url: `/admin/products`,
    method: "get",
  });
};

ProductService.update = async (id, data) => {
  return fetch({
    url: `/admin/products/` + id,
    method: "post",
    data,
  });
};

ProductService.delete = async (id) => {
  return fetch({
    url: `/admin/products/` + id,
    method: "delete",
  });
};

ProductService.UploadThankYouCardToS3 = async (data, onProgressUpload) => {
  return fetch({
    url: `/admin/customization/thank-you-card`,
    method: "post",
    headers: { "Content-Type": "multipart/form-data" },
    data,
    onUploadProgress: onProgressUpload,
  });
};

ProductService.DeleteThankYouCardToS3 = async (id, data) => {
  return fetch({
    url: `/admin/customization/thank-you-card/${id}`,
    method: "delete",
    data,
  });
};

export default ProductService;
