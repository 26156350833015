export const SIGNIN = "SIGNIN";
export const AUTHENTICATED = "AUTHENTICATED";
export const SIGNOUT = "SIGNOUT";
export const SIGNOUT_SUCCESS = "SIGNOUT_SUCCESS";
export const SIGNUP = "SIGNUP";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SHOW_AUTH_MESSAGE = "SHOW_AUTH_MESSAGE";
export const HIDE_AUTH_MESSAGE = "HIDE_AUTH_MESSAGE";
export const SHOW_LOADING = "SHOW_LOADING";
export const AUTH_TOKEN = "auth_token";
export const SIGNIN_WITH_GOOGLE = "SIGNIN_WITH_GOOGLE";
export const SIGNIN_WITH_GOOGLE_AUTHENTICATED =
  "SIGNIN_WITH_GOOGLE_AUTHENTICATED";
export const SIGNIN_WITH_FACEBOOK = "SIGNIN_WITH_FACEBOOK";
export const SIGNIN_WITH_FACEBOOK_AUTHENTICATED =
  "SIGNIN_WITH_FACEBOOK_AUTHENTICATED";
export const UPDATE_USER = "UPDATE_USER";
export const CART = "cart";
export const ITEMS_CART = "items_cart";

export const REGION_KEY = "medusa_region";

let cart_key = "";

export const setCartKey = (userId: string) => {
  cart_key = `medusa_cart_id_${userId}`;
};

export const getCartKey = () => {
  return cart_key;
};
