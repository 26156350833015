import { IS_PAAGE } from "configs/AppConfig";
import { useAdminStore } from "medusa-react";
import { useMemo } from "react";

export enum SubscriptionFeatureLevel {
  BASIC = "basic",
  MEDIUM = "medium",
  ADVANCED = "advanced",
}

export const useIsPremium = () => {
  const { store, isLoading } = useAdminStore();
  const isPremium = useMemo(() => {
    return !!store?.subscription_tier && store.subscription_tier !== "free";
  }, [store?.subscription_tier]);
  return { isPremium, isLoading };
};

export const useStoreCurrency = () => {
  const { store, isLoading } = useAdminStore();
  const storeCurrency = useMemo(() => {
    return store?.default_currency_code || "eur";
  }, [store?.default_currency_code]);
  return { storeCurrency: storeCurrency, isLoading };
};

export const useTransactionFee = () => {
  const { store, isLoading } = useAdminStore();

  const transaction_fee = useMemo(() => {
    return (store?.subscription?.transaction_fees_rate ?? 8) / 100;
  }, [store]);

  return { transaction_fee, isLoading };
};
