import React from "react";
import { useAuth } from "context/auth-context"; // Adjust the import path as necessary
import { Navigate, Outlet, useLocation } from "react-router-dom";
import {
  AUTH_PREFIX_PATH,
  REDIRECT_URL_KEY,
  UNAUTHENTICATED_ENTRY,
} from "configs/RoutesConfig";

// Define the ProtectedRoute as a React Function Component
export const ProtectedRoute: React.FC = () => {
  const { token } = useAuth();
  const location = useLocation();

  // Check if the user is authenticated
  if (!token) {
    return (
      <Navigate
        to={`${AUTH_PREFIX_PATH}${UNAUTHENTICATED_ENTRY}?${REDIRECT_URL_KEY}=${encodeURIComponent(
          location.pathname + location.search
        )}&${location.search}`}
        replace
      />
    );
  }

  // If authenticated, render the child routes
  return <Outlet />;
};
