import { createContext, useContext, useState, ReactNode } from "react";

interface DrawerContextType {
  openDrawers: number;
  openDrawer: () => void;
  closeDrawer: () => void;
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

export const useDrawerContext = () => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawerContext must be used within a DrawerProvider");
  }
  return context;
};

export const DrawerProvider = ({ children }: { children: ReactNode }) => {
  const [openDrawers, setOpenDrawers] = useState(0);
  const openDrawer = () => setOpenDrawers((prev) => prev + 1);
  const closeDrawer = () => setOpenDrawers((prev) => prev - 1);

  return (
    <DrawerContext.Provider value={{ openDrawer, openDrawers, closeDrawer }}>
      {children}
    </DrawerContext.Provider>
  );
};
