import React, { ReactNode, useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { API_BASE_URL, IS_PAAGE, THEME_CONFIG } from "./configs/AppConfig";
import { CartProvider, MedusaProvider } from "medusa-react";
import { createMedusaClient, queryClient } from "configs/MedusaConfig";
import { StoreProvider } from "context/store-context";
import { AccountProvider } from "context/account-context";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import AuthProvider, { useAuth } from "context/auth-context";
import { AppRoutes } from "routes";
import i18n from "./configs/i18nextConfig";
import { I18nextProvider } from "react-i18next";
import { MetaTagBackground } from "utils/meta-update";
import { DrawerProvider } from "context/drawer-context";
import { useSVHSuport } from "hooks/utils-hooks";

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

// Define a type for the props expected by MedusaProviderWithAuth
type MedusaProviderWithAuthProps = {
  children: ReactNode;
};

// Use the props type in your component
const MedusaProviderWithAuth: React.FC<MedusaProviderWithAuthProps> = ({
  children,
}) => {
  const { token } = useAuth();

  const medusaClient = useMemo(() => createMedusaClient(token), [token]);

  return (
    <MedusaProvider
      baseUrl={API_BASE_URL}
      medusaClient={medusaClient}
      queryClientProviderProps={{
        client: queryClient,
      }}
      customHeaders={
        !!token && token !== null
          ? {
              Authorization: `Bearer ${token}`,
            }
          : {}
      }
    >
      {children}
    </MedusaProvider>
  );
};

//APP

const App: React.FC = () => {
  const [isInitialized, setIsInitialized] = React.useState(false);

  useEffect(() => {
    // Access the environment variable

    // Conditionally set the document title
    if (IS_PAAGE) {
      document.title = "Paage - Link-in-bio with built-in commerce";
    } else {
      document.title = "LAMA - Kickstart your creative business now!";
    }
  }, [IS_PAAGE]);
  useEffect(() => {
    const checkInitialization = () => {
      if (i18n.isInitialized) {
        setIsInitialized(true);
      }
    };

    i18n.on("initialized", checkInitialization);
    checkInitialization(); // initial check

    return () => {
      i18n.off("initialized", checkInitialization);
    };
  }, []);

  useEffect(() => {
    //Update the header color of mobilebrowsers
    MetaTagBackground("#fff");
  }, []);

  useSVHSuport();

  return isInitialized ? (
    <div className="App">
      <AuthProvider>
        <Provider store={store}>
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <MedusaProviderWithAuth>
              <CartProvider>
                <BrowserRouter basename={"/" + i18n.language}>
                  <I18nextProvider i18n={i18n}>
                    <AccountProvider>
                      <StoreProvider>
                        <DrawerProvider>
                          <AppRoutes />
                        </DrawerProvider>
                      </StoreProvider>
                    </AccountProvider>
                  </I18nextProvider>
                </BrowserRouter>
              </CartProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </MedusaProviderWithAuth>
          </ThemeSwitcherProvider>
        </Provider>
      </AuthProvider>
    </div>
  ) : null;
};

export default App;
