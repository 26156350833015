import { lazy, ComponentType, LazyExoticComponent } from "react";

// Extend the function to accept a uniqueId parameter for the component
const lazyWithRetry = <T extends ComponentType<any>>(
  componentImport: () => Promise<{ default: T }>,
  uniqueId: string // Add a unique identifier for each component
): LazyExoticComponent<T> =>
  lazy(async () => {
    const localStorageKey = `refresh-count-${uniqueId}`; // Use uniqueId to create a unique local storage key
    const pageHasAlreadyBeenForceRefreshed = JSON.parse(
      window.localStorage.getItem(localStorageKey) || "false"
    );

    try {
      const component = await componentImport();
      // Reset refresh count on successful load

      window.localStorage.setItem(localStorageKey, "false");

      return component;
    } catch (error) {
      if (!pageHasAlreadyBeenForceRefreshed) {
        // Increment and store refresh count
        window.localStorage.setItem(localStorageKey, "true");
        window.location.reload();
        // Need to cast as never to satisfy TypeScript's control flow analysis
        return new Promise(() => {}) as never; // Prevent TypeScript error
      }
      throw error;
    }
  });

export default lazyWithRetry;
