import Medusa from "@medusajs/medusa-js";
import { QueryClient } from "@tanstack/react-query";
import { API_BASE_URL } from "./AppConfig";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60 * 24,
      retry: 1,
    },
  },
});

// Function to create a new Medusa client instance with dynamic headers
function createMedusaClient(token) {
  return new Medusa({
    baseUrl: API_BASE_URL,
    maxRetries: 3,
    customHeaders: token ? { Authorization: `Bearer ${token}` } : {}, // Add custom headers based on the token
  });
}

export { queryClient, createMedusaClient };
