import { LineItem, ProductType, StoreCartsRes } from "@medusajs/medusa";
import { useCart, useProductTypes } from "medusa-react";
import { useEffect, useMemo, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import fetch from "auth/FetchInterceptor";
import ProductService from "services/ProductService";
import { getPriceForQuantity } from "utils/helper";
import { useAccount } from "context/account-context";

const customizationItems = ["thank-you-card", "packaging-logo"];

export function useCustomizationItems() {
  const { cart } = useCart();
  const { product_types } = useProductTypes();

  const useCustomizationItems = useMemo(() => {
    const type = product_types?.filter((type) =>
      customizationItems.includes(type.value)
    );

    return cart?.items?.filter(
      (item) =>
        !!type?.find((type) => type.id === item.variant?.product?.type_id)
    );
  }, [cart?.items, product_types]);

  return useCustomizationItems;
}

export function useProductCertificateItems(variantId: string | undefined): {
  productCertificates: {
    customCertificates: LineItem[] | undefined;
    autoCertificates: LineItem[] | undefined;
  };
  certificateTypeId: ProductType | undefined;
  autoCertificateTypeId: ProductType | undefined;
} {
  const { cart } = useCart();
  const { product_types } = useProductTypes();
  const certificateTypeId = product_types?.find(
    (type) => type.value === "certificate"
  );
  const autoCertificateTypeId = product_types?.find(
    (type) => type.value === "auto-certificate"
  );

  const productCertificates = useMemo(() => {
    const customCertificates = cart?.items
      ?.filter(
        (item) => item.variant?.product?.type_id === certificateTypeId?.id
      )
      .filter(
        (item) =>
          item.metadata?.variant_id === variantId &&
          item.variant_id !== variantId
      );
    const autoCertificates = cart?.items
      ?.filter(
        (item) => item.variant?.product?.type_id === autoCertificateTypeId?.id
      )
      .filter(
        (item) =>
          item.metadata?.variant_id === variantId &&
          item.variant_id !== variantId
      );
    return { customCertificates, autoCertificates };
  }, [autoCertificateTypeId, cart?.items, certificateTypeId, variantId]);

  return { productCertificates, certificateTypeId, autoCertificateTypeId };
}

export function useCoreItems() {
  const { cart } = useCart();
  const useCoreItems = useMemo(() => {
    return cart?.items?.filter(
      (item) => item.variant?.product?.type_id === null
    );
  }, [cart?.items]);

  return useCoreItems;
}

export function useBulkUpdateLineItems(cartId: string) {
  const { setCart } = useCart();
  return useMutation<StoreCartsRes, any, any>(
    async (data: { [key: string]: { quantity: number; metadata?: any } }) => {
      const res = await fetch(`/store/carts/${cartId}/bulk/line-items`, {
        method: "POST",
        data: data,
      });
      return res as unknown as StoreCartsRes;
    },
    {
      onSuccess: (data) => {
        setCart(data.cart);
        return;
      },
      onError: (error) => {
        console.log(error);
        return;
      },
    }
  );
}

export function useOriginalPrices(cart) {
  const [originalPrices, setOriginalPrices] = useState<
    { variant_id: string; original_price: number }[]
  >([]);
  const { currentPricing } = useAccount();

  useEffect(() => {
    const loadOriginalPrices = async () => {
      try {
        let originalPrices_: { variant_id: string; original_price: number }[] =
          [];
        if (cart) {
          for (const item of cart.items) {
            if (item.quantity > 1) {
              const data: any = await ProductService.GetVariant(
                item.variant.id
              );
              originalPrices_.push({
                variant_id: item.id as string,
                original_price:
                  getPriceForQuantity(
                    currentPricing === "public_price" ? null : 1,
                    data.variant.prices,
                    cart.region.currency_code
                  ) || 0,
              });
            } else {
              originalPrices_.push({
                variant_id: item.id as string,
                original_price: item.unit_price as number,
              });
            }
          }
        }
        setOriginalPrices(originalPrices_);
      } catch (e) {
        console.log(e);
      }
    };
    if (
      cart &&
      cart.items.length > 0 &&
      (originalPrices.length !== cart.items.length ||
        originalPrices.some(
          (price) => !cart.items.find((item) => item.id === price.variant_id)
        ))
    ) {
      loadOriginalPrices();
    }
  }, [JSON.stringify(cart)]);

  return originalPrices;
}
