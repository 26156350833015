export function MetaTagBackground(color: string) {
  // Update Chrome, Firefox OS and Opera theme color
  const themeColorMetaTag = document.querySelector('meta[name="theme-color"]');
  if (themeColorMetaTag) {
    themeColorMetaTag.setAttribute("content", color);
  } else {
    const newThemeColorMetaTag = document.createElement("meta");
    newThemeColorMetaTag.name = "theme-color";
    newThemeColorMetaTag.content = color;
    document.head.appendChild(newThemeColorMetaTag);
  }

  // Update Windows Phone nav button color
  const msNavButtonColorMetaTag = document.querySelector(
    'meta[name="msapplication-navbutton-color"]'
  );
  if (msNavButtonColorMetaTag) {
    msNavButtonColorMetaTag.setAttribute("content", color);
  } else {
    const newMsNavButtonColorMetaTag = document.createElement("meta");
    newMsNavButtonColorMetaTag.name = "msapplication-navbutton-color";
    newMsNavButtonColorMetaTag.content = color;
    document.head.appendChild(newMsNavButtonColorMetaTag);
  }

  // Update iOS Safari status bar style
  const appleStatusBarStyleMetaTag = document.querySelector(
    'meta[name="apple-mobile-web-app-status-bar-style"]'
  );
  if (appleStatusBarStyleMetaTag) {
    appleStatusBarStyleMetaTag.setAttribute("content", color);
  } else {
    const newAppleStatusBarStyleMetaTag = document.createElement("meta");
    newAppleStatusBarStyleMetaTag.name =
      "apple-mobile-web-app-status-bar-style";
    newAppleStatusBarStyleMetaTag.content = color;
    document.head.appendChild(newAppleStatusBarStyleMetaTag);
  }
}
